body {
  margin: 0;
  font-family: "Pretendard Variable" !important;
  -webkit-font-smoothing: "Pretendard Variable";
  -moz-osx-font-smoothing: "Pretendard Variable";
}

.fixed-width-container {
  width: 1920px;
  margin: 0 auto;
}

@media (min-width: 1920px) {
  .fixed-width-container {
    width: 100%;
    overflow-x: visible;
  }
}

p {
  font-family: "Pretendard Variable" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.positive-highlight {
  color: #03BF38;
  font-size: 16px;
  font-family: Pretendard Variable;
  font-weight: 700;
  line-height: 22.40px;
  word-wrap: break-word
}

.negative-highlight {
  color: red;
  font-size: 16px;
  font-family: Pretendard Variable;
  font-weight: 700;
  line-height: 22.40px;
  word-wrap: break-word
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 200;
  src: url(assets/fonts/Pretendard-ExtraLight.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 300;
  src: url(assets/fonts/Pretendard-Light.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 400;
  src: url(assets/fonts/Pretendard-Regular.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 500;
  src: url(assets/fonts/Pretendard-Medium.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 600;
  src: url(assets/fonts/Pretendard-SemiBold.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 700;
  src: url(assets/fonts/Pretendard-Bold.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 800;
  src: url(assets/fonts/Pretendard-ExtraBold.otf);
}

@font-face {
  font-family: "Pretendard Variable";
  font-weight: 900;
  src: url(assets/fonts/Pretendard-Black.otf);
}

.react-datepicker__input-container .wtt {
  border-radius: 6px !important;
}

.create-hotel-input div {
  border-radius: 5px;
}

.create-hotel-input input {
  font-size: 16px;
  font-weight: 700;
  color: #111111;
}